<template>
  <f7-page class="article-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <HomeNavigationComponent search="article" @searched="onSearch" />
    </template>

    <section class="article-container">
      <ArticleListComponent ref="articleListComponent" mode="article" :size="16" />
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref } from "vue";

// import HomeNavigationComponent from "@/components/navigations/HomeNavigationComponent.vue";
// import ArticleListComponent from "@/components/ArticleListComponent.vue";

const HomeNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "home-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/HomeNavigationComponent.vue"));
const ArticleListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "article-list" */ /* webpackMode: "lazy" */ "@/components/ArticleListComponent.vue"));

export default defineComponent({
  name: "ArticlePage",
  components: {
    HomeNavigationComponent,
    ArticleListComponent,
  },
  props: { f7router: Object },
  setup() {
    const ArticleListComponent = ref(false);
    const showPreloader = ref(false);

    return { ArticleListComponent, showPreloader };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      await this.$refs.articleListComponent.loadMore();
      this.showPreloader = false;
    },
    onSearch(searchValue) {
      this.$refs.articleListComponent.search(searchValue);
    },
  },
});
</script>
